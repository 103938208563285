import React from 'react';
import { Route } from 'react-router';

class DoRedirect extends React.PureComponent {
  componentDidMount() {
    const { to, match } = this.props;
    if (typeof to === 'function') {
      match.history.replace(to(match));
    } else {
      match.history.replace(to);
    }
  }

  render() {
    return null;
  }
}

const Redirect = ({ from, exact, to }) => (
  <Route path={from} exact={exact} render={(match) => <DoRedirect to={to} match={match} />} />
);

export default Redirect;
