import { useContext } from 'preact/hooks';
import { InternalAuthContext } from '../internal-auth-context';

/**
 * Hook to access the {@see InternalAuthHelper} instance provided
 *   by a parent {@see InternalAuthProvider}. This hook can only
 *   be used within a component that is a descendant of said
 *   provider.
 */
export const useInternalAuth = () => useContext(InternalAuthContext);
