import { createContext } from 'preact';
import { InternalAuthHelper } from './internal-auth-helper';

/**
 * Create the Internal Authorization Context. Seeds it with a
 *   dummy helper class.
 */
export const InternalAuthContext = createContext<InternalAuthHelper>({
  login() {},
  logout() {},
  isLoggedIn() {
    return false;
  },
  getJwtToken() {
    return undefined;
  },
  getJwtInfo() {
    return undefined;
  },
  getUserGroups() {
    return [];
  },
  loggedInFetch() {
    throw new Error('Not ready.');
  }
} as unknown as InternalAuthHelper);
