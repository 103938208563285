import { JSX } from 'preact';
import { InternalAuthContext } from '../internal-auth-context';
import { useInternalAuthHelper } from '../internal-auth-helper';

export interface InternalAuthProviderProps {
  /**
   * Base URL for authorization endpoints.
   */
  apiBaseUrl: string;
  children: JSX.Element;
}

/**
 * A context provider for {@see InternalAuthHelper}. Allows any
 *   child components to leverage our internal authorization
 *   tooling via the {@see useInternalAuth} hook.
 *
 * There should only be one of these providers in the application,
 *   as the helper class interacts with the browser's local storage
 *   and hash parameters and having multiple instances could lead
 *   to unexpected behavior.
 *
 * @param props - {@see InternalAuthProviderProps}
 */
export const InternalAuthProvider = ({ apiBaseUrl, children }: InternalAuthProviderProps) => {
  const internalAuthHelper = useInternalAuthHelper({ apiBaseUrl });
  return (
    <InternalAuthContext.Provider value={internalAuthHelper}>
      {children}
    </InternalAuthContext.Provider>
  );
};
