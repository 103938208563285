/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useMemo } from 'preact/hooks';
import {
  AuthHelper,
  BaseAuthHelperProps,
  TokenInfo,
  useBaseAuthHelper
} from '../../hooks/use-base-auth-helper';
import {
  INTERNAL_AUTH_LOGIN_HINT_KEY,
  INTERNAL_AUTH_TOKEN_HASH_PARAMETER,
  INTERNAL_AUTH_TOKEN_LOCAL_KEY
} from '../constants';

/** Decoded JWT token for the user. */
export interface InternalAuthTokenInfo extends TokenInfo {
  ['https://auth.ownup.com/groups']: string[];
}

export interface InternalAuthHelper extends AuthHelper<InternalAuthTokenInfo> {
  /** The user's groups. */
  userGroups: string[];
}

export interface InternalAuthHelperProps extends Pick<BaseAuthHelperProps, 'fetchFunction'> {
  /**
   * The root url all login/logout requests will go through.
   *
   * Should _not_ have a trailing slash.
   */
  apiBaseUrl: string;
}

/**
 * A helper for managing internal authorization state. You should not
 *   use this one directly in product, instead opting to use the
 *   {@see InternalAuthProvider} and {@see useInternalAuth} hook.
 */
export const useInternalAuthHelper = ({
  apiBaseUrl,
  fetchFunction
}: InternalAuthHelperProps): InternalAuthHelper => {
  const { isLoggedIn, tokenInfo, loggedInFetch, jwtToken, setJwtToken } =
    useBaseAuthHelper<InternalAuthTokenInfo>({
      hashParameterTokenKey: INTERNAL_AUTH_TOKEN_HASH_PARAMETER,
      localStorageTokenKey: INTERNAL_AUTH_TOKEN_LOCAL_KEY,
      fetchFunction
    });

  /**
   * Store the user's groups in a separate variable for easier access.
   */
  const userGroups = useMemo(() => tokenInfo?.['https://auth.ownup.com/groups'] || [], [tokenInfo]);

  /**
   * When the user has a valid token, store the email on the token as
   *   a login hint for future logins. This will allow them to skip
   *   the user selection screen (provided they still have access to
   *   that account.)
   */
  useEffect(() => {
    if (tokenInfo) {
      localStorage[INTERNAL_AUTH_LOGIN_HINT_KEY] = tokenInfo.email;
    }
  }, [tokenInfo]);

  /**
   * Immediately redirect the user to the login page. If the user has
   *   a login hint stored in their localStorage it will be sent along
   *   as part of the login request.
   *
   * @param redirectUrl - The page the user wants to go to after
   *   logging in. This _must_ be provided and must be a URL that
   *   is allowed by the server.
   */
  const login = useCallback(
    async (redirectUrl: string): Promise<void> => {
      const loginHint = localStorage[INTERNAL_AUTH_LOGIN_HINT_KEY];
      const queryStringParameters = new URLSearchParams({
        ...(redirectUrl ? { redirectUrl } : {}),
        ...(loginHint ? { loginHint } : {})
      }).toString();
      window.location.href = `${apiBaseUrl}/auth/login${queryStringParameters ? `?${queryStringParameters}` : ''}`;
    },
    [apiBaseUrl]
  );

  /**
   * Immediately remove the user's authentication state and ask the server
   *   to log the user out. Will proceed as a logout even if the server
   *   fails to process the logout or takes too long (5s)
   *
   * @param redirectUrl - The page the user wants to go to after
   *   logging out.
   */
  const logout = useCallback(
    async (redirectUrl?: string): Promise<void> => {
      // Do not need to wait for the server-side logout to complete -- it is a
      //   best-effort attempt to remove the user's session. If it fails, the
      //   session will be left to expire on its own.
      await Promise.race([
        loggedInFetch(`${apiBaseUrl}/auth/logout`, { method: 'POST' }).catch((err) =>
          console.error('Error logging out:', err)
        ),
        new Promise<void>((res) => setTimeout(res, 5000))
      ]);
      setJwtToken(undefined);
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    },
    [apiBaseUrl, loggedInFetch, setJwtToken]
  );

  return {
    login,
    logout,
    isLoggedIn,
    jwtToken,
    tokenInfo,
    loggedInFetch,
    userGroups
  };
};
