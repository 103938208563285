import { useInternalAuth } from '@rategravity/widgets-react/modules/auth/internal/hooks/use-internal-auth';
import { SplitFactory } from '@splitsoftware/splitio-react';
import React, { useMemo } from 'react';

const splitUrl = process.env.SPLIT_URL || 'https://staging.split.ownup.com';

export const SplitProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { tokenInfo } = useInternalAuth();
  const email = tokenInfo?.email || 'not_logged_in';
  const config = useMemo(
    () => ({
      core: {
        authorizationKey: process.env.SPLIT_API_KEY || 'qdjduua8u5orl12gguii27vam9g73rccr5lf',
        key: email,
        trafficType: 'internal'
      },
      urls: {
        sdk: `${splitUrl}/sdk-api`,
        events: `${splitUrl}/events-api`,
        auth: `${splitUrl}/auth-api`
      }
    }),
    [email]
  );
  return (
    <SplitFactory config={config}>
      <React.Fragment>{children}</React.Fragment>
    </SplitFactory>
  );
};
