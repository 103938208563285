export interface CreateLoggedInFetchOptions {
  /**
   * A function that will be invoked every time the logged-in fetch
   *   is called to get the JWT token that will be used for the
   *   Authorization header.
   */
  getJwtToken: () => string | undefined;
  /**
   * Optional fetch function to use instead of the global fetch.
   *   Mainly used for testing purposes.
   */
  fetchFunction?: typeof fetch;
}

/**
 * Wrap a fetch function to automatically add the Authorization header
 *   based on the JWT token resulting from the `getJwtToken` function.
 *
 * @param options - {@see CreateLoggedInFetchOptions}
 */
export const createLoggedInFetch =
  ({ getJwtToken, fetchFunction }: CreateLoggedInFetchOptions) =>
  async (
    info: RequestInfo,
    { headers: requestHeaders, ...init }: RequestInit = { headers: {} },
    onUnauthorized?: (response: Response) => Promise<Response>
  ) => {
    const headers = new Headers(requestHeaders);
    // Set the Authorization header if a token is present
    const jwtToken = getJwtToken();
    if (jwtToken) {
      headers.set('Authorization', `Bearer ${jwtToken}`);
    }
    // Set the Content-Type header to JSON if it is not already set
    if (!headers.has('Content-Type')) {
      headers.append('Content-Type', 'application/json');
    }
    // Fallback to the global fetch function if none is provided
    const response = await (fetchFunction || fetch)(info, { headers, ...init });
    // If the server returns a 401 Unauthorized, call the onUnauthorized
    //   function if it is provided
    if (response.status === 401 && onUnauthorized) {
      return onUnauthorized(response);
    }
    return response;
  };
