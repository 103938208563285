import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';

const Link = ({ to, onClick, children, ...props }) => (
  <a
    href={to}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }}
    {...props}
  >
    {children}
  </a>
);

export default connect(
  () => ({}),
  (dispatch, { to }) => ({
    onClick: () => dispatch(push(to))
  })
)(Link);
