/** `localStorage` key where internal auth tokens will be stored */
export const INTERNAL_AUTH_TOKEN_LOCAL_KEY = 'own-up-internal-auth-token';
/**
 * `localStorage` key where internal auth login hints will be stored.
 *   This will allow us to keep _some_ context of the user's session
 *   even after their session has expired.
 */
export const INTERNAL_AUTH_LOGIN_HINT_KEY = 'own-up-internal-login-hint';
/** URL hash parameter where internal auth tokens will be transferred */
export const INTERNAL_AUTH_TOKEN_HASH_PARAMETER = 'id_token';
