import React from 'react';
import { Route } from 'react-router';
import Link from './link';

const NavLink = ({ to, exact, className, activeClassName, children, ...props }) => (
  <Route path={to} exact={exact}>
    {({ match }) => (
      <Link className={`${className} ${match !== null ? activeClassName : ''}`} to={to} {...props}>
        {children}
      </Link>
    )}
  </Route>
);

export default NavLink;
