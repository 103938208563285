import { useEffect, useMemo, useState } from 'preact/hooks';
import { createLoggedInFetch } from '../../create-logged-in-fetch';

/** Decoded JWT token for the user. */
export interface TokenInfo extends Record<string, unknown> {
  /** User's internal Google ID */
  sub: string;
  /** User's email */
  email: string;
}

export interface AuthHelper<T extends TokenInfo = TokenInfo> {
  /**
   * Begin the login process immediately.
   *
   * @param redirectUrl - The page the user wants to go to after
   *   logging in.
   */
  login: (redirectUrl: string) => Promise<void>;
  /**
   * Begin the logout process immediately
   *
   * @param redirectUrl - The page the user wants to go to after
   *   logging out.
   */
  logout: (redirectUrl?: string) => Promise<void>;
  /** If the user is currently logged in or not */
  isLoggedIn: boolean;
  /** The user's JWT token. */
  jwtToken: string | undefined;
  /** Decoded JWT token. */
  tokenInfo: T | undefined;
  /**
   * Attach the Authorization header (if present) to a fetch request
   *
   * @param onUnauthorized - Optional function to call if the server
   *   returns an unauthorized error.
   */
  loggedInFetch: (
    info: RequestInfo,
    init?: RequestInit,
    onUnauthorized?: (response: Response) => Promise<Response>
  ) => Promise<Response>;
}

/**
 * Converts a raw encoded JWT token into its full JSON
 *   representation.
 *
 * @param token - Raw JWT token to decode.
 */
export const decodeJwt = <T extends TokenInfo>(token?: string): T | undefined => {
  if (token) {
    try {
      const payload = token.split('.')[1];
      const decoded = atob(payload);
      return JSON.parse(decoded);
    } catch (e) {
      console.error('Failed to parse JWT token', e);
    }
  }
  return undefined;
};

export interface BaseAuthHelperProps {
  /**
   * Key at which the JWT token may be transferred from the URL hash
   */
  hashParameterTokenKey: string;
  /**
   * Key at which the JWT token is stored in local storage.
   */
  localStorageTokenKey: string;
  /**
   * Optional override of the fetch function to use. Defaults to the
   *   global fetch function.
   */
  fetchFunction?: typeof fetch;
}

/**
 * Attempt to fetch a JWT token from the URL hash.
 *
 * If one is found, it will be removed from the hash so the user can't
 *   see it anymore, which helps prevent the token from being leaked
 *   accidentally by users sharing URLs/screenshots/etc.
 *
 * If one is not found in the hash, it'll fall back to the token
 *   stored in local storage (if present).
 */
const initializeJwt = (
  hashParameterTokenKey: string,
  localStorageTokenKey: string
): string | undefined => {
  if (window.location.hash.includes(hashParameterTokenKey)) {
    const match = window.location.hash.match(
      new RegExp(`(&|#)${hashParameterTokenKey}=(.+?)(&|$)`)
    );
    if (match) {
      const [fullMatch, replacement, token] = match;
      // Remove the token from the hash params
      window.location.hash = window.location.hash.replace(fullMatch, replacement);
      // No need to include a separator at the end of the hash
      if (window.location.hash.endsWith(replacement)) {
        window.location.hash = window.location.hash.substring(
          0,
          window.location.hash.length - replacement.length
        );
      }
      localStorage.setItem(localStorageTokenKey, token);
      return token;
    }
  }
  return localStorage[localStorageTokenKey];
};

/**
 * A base class for Authorization Helpers that provides common functionality
 *   such as initializing the JWT token from local storage or the URL hash
 *   and parsing the token into a JSON object. The class can also be used to
 *   perform fetch requests with the JWT token attached to the request.
 *
 * This is an abstract class so anyone using it will have to bring their own
 *   implementations of login/logout functionality.
 */
export const useBaseAuthHelper = <T extends TokenInfo = TokenInfo>({
  hashParameterTokenKey,
  localStorageTokenKey,
  fetchFunction
}: BaseAuthHelperProps): Omit<AuthHelper<T>, 'login' | 'logout'> & {
  setJwtToken: (token: string | undefined) => void;
} => {
  const [jwtToken, setJwtToken] = useState<string | undefined>(
    initializeJwt(hashParameterTokenKey, localStorageTokenKey)
  );
  const tokenInfo = useMemo(() => decodeJwt<T>(jwtToken), [jwtToken]);
  const isLoggedIn = useMemo(() => !!jwtToken, [jwtToken]);

  /**
   * Update localStorage when the jwt token is changed.
   */
  useEffect(() => {
    if (jwtToken) {
      localStorage.setItem(localStorageTokenKey, jwtToken);
    } else {
      localStorage.removeItem(localStorageTokenKey);
    }
  }, [jwtToken, localStorageTokenKey]);

  /**
   * Curry the `createLoggedInFetch` function with this instance's
   *   `jwtToken` and `fetchFunction`. We use a `useMemo` instead
   *   of a `useCallback` here because we want the result of the
   *   curried function (the wrapped fetch function) to be memoized
   *   rather than the `createLoggedInFetch` function itself.
   */
  const loggedInFetch = useMemo(
    () =>
      createLoggedInFetch({
        fetchFunction,
        getJwtToken: () => jwtToken
      }),
    [jwtToken, fetchFunction]
  );

  return {
    isLoggedIn,
    jwtToken,
    tokenInfo,
    setJwtToken,
    loggedInFetch
  };
};
